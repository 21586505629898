import React, { useState } from 'react';
import { Lock, Mail, User } from 'lucide-react'; // Íconos para los campos de entrada
import { auth, database } from '../../Config/firebase'; // Asegúrate de que la ruta sea correcta
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, set } from 'firebase/database';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate para redirigir
import FootballLoading from '../../Utils/FootballLoading';

const generateReferralCode = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let referralCode = '';
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    referralCode += characters[randomIndex];
  }
  return referralCode;
};

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false); // Estado para el indicador de carga
  const navigate = useNavigate(); // Hook para redirigir

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, password } = formData;
    
    setLoading(true); // Mostrar indicador de carga

    try {
      // Registrar al usuario
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      // Guardar el UID del usuario en el localStorage
      localStorage.setItem('userUUID', user.uid);

      // Generar código de referido
      const referralCode = generateReferralCode();

      // Guardar usuario y código de referido en la base de datos
      await set(ref(database, 'usuarios/' + user.uid), {
        name: name,
        email: email,
        referralCode: referralCode,
        password: password 
      });

    //   alert('Registro exitoso. Tu código de referido es: ' + referralCode);
      
      // Redirigir al login después del registro exitoso
      navigate('/home');
    } catch (error) {
      console.error('Error al registrar usuario:', error);
    //   alert('Error al registrar usuario. Intenta de nuevo.');
    } finally {
      setLoading(false); // Ocultar indicador de carga
    }
  };

  if (loading) {
    return <FootballLoading />; // Mostrar indicador de carga mientras se procesa el registro
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <div className="w-full max-w-md bg-gray-900 rounded-lg shadow-lg p-8">
        <div className="flex justify-center mb-6">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2" // Cambia esto por la ruta de tu logo
            alt="Logo"
            className="w-24 h-24 rounded-full border-4 border-red-500"
          />
        </div>
        <h2 className="text-2xl font-bold mb-4 text-center text-red-500">Crear Cuenta</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-white">Nombre</label>
            <div className="relative">
              <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full pl-10 pr-4 py-2 border bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Ingresa tu nombre"
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-white">Correo Electrónico</label>
            <div className="relative">
              <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full pl-10 pr-4 py-2 border bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Ingresa tu correo electrónico"
                required
              />
            </div>
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block text-white">Contraseña</label>
            <div className="relative">
              <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full pl-10 pr-4 py-2 border bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Ingresa tu contraseña"
                required
              />
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Registrar
          </button>
        </form>
        <div className="mt-4 text-center">
          <p className="text-white">¿Ya tienes una cuenta? <a href="/login" className="text-indigo-600 hover:underline">Inicia sesión</a></p>
        </div>
      </div>
    </div>
  );
};

export default Register;
