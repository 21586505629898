import { useState, useEffect } from "react";
import { getDatabase, ref, onValue, update } from "firebase/database";

const useUserData = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const db = getDatabase();
    const uid = localStorage.getItem("userUUID"); // Asegúrate de que sea siempre "userUUID"

    if (uid) {
      const userRef = ref(db, `usuarios/${uid}`);

      const unsubscribe = onValue(
        userRef,
        (snapshot) => {
          if (snapshot.exists()) {
            setUserData(snapshot.val());
            setError(null); // Limpiar cualquier error anterior
          } else {
            setError("No se encontraron datos para este usuario.");
            setUserData(null);
          }
          setLoading(false);
        },
        (error) => {
          setError(error.message);
          setLoading(false);
        }
      );

      // Limpiar la suscripción cuando el componente se desmonte
      return () => unsubscribe();
    } else {
      // Si no hay uid, el usuario no está autenticado
      setLoading(false);
    }
  }, []);

  // Función para actualizar los datos del usuario
  const updateUserData = async (newData) => {
    try {
      const db = getDatabase();
      const uid = localStorage.getItem("userUUID"); // Consistencia en el nombre de la clave

      if (uid) {
        const userRef = ref(db, `usuarios/${uid}`);
        await update(userRef, newData);
        setUserData((prevData) => ({ ...prevData, ...newData }));
      } else {
        throw new Error("Usuario no autenticado.");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return { userData, loading, error, updateUserData };
};

export default useUserData;
