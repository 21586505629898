import React, { useState } from 'react';
import { 
  Menu, X, Home as HomeIcon, User, HelpCircle, Award, 
  Gem, MessageCircle, FileClock, 
} from 'lucide-react';
import FootballLoading from '../../Utils/FootballLoading';
import useUserData from '../../Hooks/useUserData';
import UserProfile from '../Profile/UserProfile';
import SupportChat from '../Chat/Chat';
import BetFree from '../Free/BetFree';
import BetVip from '../Vip/BetVip';
import InviteGrupo from '../World/InviteGrupo';
import BetHistory from '../Free/BetHistory';
import { Link } from 'react-router-dom';

const Home = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentView, setCurrentView] = useState('home');
  const { userData, loading, error } = useUserData();

  // Muestra un loading mientras se carga la información del usuario
  if (loading) return <FootballLoading />;
  if (error) return <p>Error: {error}</p>;

  // Función para alternar la visibilidad del menú lateral
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Menú de navegación lateral
  const MenuItem = ({ icon: Icon, text, view, children }) => (
    <li
      className="flex items-center justify-between p-3 hover:bg-indigo-600 rounded-lg transition-colors duration-200 cursor-pointer"
      onClick={() => {
        setCurrentView(view);
        setIsMenuOpen(false);
      }}
    >
      <div className="flex items-center space-x-3">
        <Icon className="w-5 h-5 text-white" />
        <span className="text-white font-semibold">{text}</span>
      </div>
      {children && <div>{children}</div>}
    </li>
  );

  // Renderiza el contenido de acuerdo a la vista seleccionada
  const renderContent = () => {
    switch (currentView) {
      case 'profile':
        return <UserProfile />;
      case 'help':
        return <SupportChat />;
      case 'bet':
        return <Home limit={userData ? undefined : 3} />;
      case 'vip':
        return <BetVip />;
      case 'whatsapp':
        return <InviteGrupo />;
      case 'history':
        return <BetHistory />;
      default:
        // Muestra máximo 2 pronósticos si el usuario no está autenticado
        return (
          <>
            <BetFree limit={userData ? undefined : 3} />
            {!userData && (
              <div className="fixed bottom-0 left-0 w-full bg-gray-900 p-6 shadow-lg flex flex-col items-center z-50">
                <p className="text-gray-300 mb-4 text-lg font-semibold text-center">
                  Inicie sesión o cree una cuenta para ver más pronósticos
                </p>
                <div className="flex space-x-4">
                  <Link to="/login">
                    <button className="bg-gradient-to-r from-blue-600 to-blue-500 text-white font-bold px-6 py-3 rounded-full transition-transform transform hover:scale-105 hover:shadow-lg">
                      Iniciar sesión
                    </button>
                  </Link>
                  <Link to="/register">
                    <button className="bg-gradient-to-r from-green-600 to-green-500 text-white font-bold px-6 py-3 rounded-full transition-transform transform hover:scale-105 hover:shadow-lg">
                      Crear cuenta
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </>
        );
    
    }
  };

  return (
    <div className="bg-gray-900 min-h-screen flex flex-col">
      {/* Header */}
      <header className="fixed top-0 left-0 w-full bg-gradient-to-r from-red-500 to-orange-600 p-4 shadow-lg z-50">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-white text-3xl font-extrabold">
            {userData ? userData.name.split(' ')[0] : 'Usuario'}
          </h1>
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none lg:hidden"
            aria-label="Toggle menu"
          >
            {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
          </button>
        </div>
      </header>

      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar Menu */}
        <div
          className={`fixed lg:static top-0 left-0 h-full w-64 bg-indigo-700 text-white p-6 transform transition-transform duration-300 ease-in-out z-20 ${
            isMenuOpen ? 'translate-x-0' : '-translate-x-full'
          } lg:translate-x-0`}
        >
          <div className="flex justify-between items-center mb-8 lg:hidden">
            <h2 className="text-xl font-semibold">Menú</h2>
            <button onClick={toggleMenu} className="text-white focus:outline-none">
              <X className="w-6 h-6" />
            </button>
          </div>
          <nav>
            <ul className="space-y-4">
              <MenuItem icon={HomeIcon} text="Inicio" view="home" />
              <MenuItem icon={User} text="Perfil" view="profile">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentView('profile-edit');
                  }}
                  className="bg-blue-500 text-xs rounded px-2 py-1"
                >
                  Editar
                </button>
              </MenuItem>
              <MenuItem icon={Award} text="Tips" view="bet">
                <span className="bg-purple-600 text-xs rounded-full px-2 py-1">Ganar🔥</span>
              </MenuItem>
              <MenuItem icon={Gem} text="Vip" view="vip">
                <span className="bg-green-600 text-xs rounded-full px-2 py-1">+90%</span>
              </MenuItem>
              <MenuItem icon={FileClock} text="Historial" view="history" />
              <MenuItem icon={MessageCircle} text="Grupo WhatsApp" view="whatsapp" />
              <MenuItem icon={HelpCircle} text="Ayuda" view="help">
                <span className="text-xs text-gray-400">FAQ</span>
              </MenuItem>
            </ul>
          </nav>
        </div>

        {/* Main Content */}
        <main className="flex-1 overflow-y-auto pt-8">
          <div className="container mx-auto">{renderContent()}</div>
        </main>
      </div>

      {/* Overlay for mobile */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-10 lg:hidden"
          onClick={toggleMenu}
        ></div>
      )}
    </div>
  );
};

export default Home;
