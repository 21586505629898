import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ element: Element }) => {
  const isAuthenticated = Boolean(localStorage.getItem('userUUID'));

  // Si está autenticado, renderiza el componente; de lo contrario, redirige a '/login'
  return isAuthenticated ? Element : <Navigate to="/login" />;
};

ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired,
};

export default ProtectedRoute;
