import React, { useState, useEffect } from 'react';
import { MessageCircle, Users, Zap, ArrowRight } from 'lucide-react';

export default function InviteGrupo() {
  const [isVisible, setIsVisible] = useState(false);
  const [memberCount, setMemberCount] = useState(0);

  useEffect(() => {
    setIsVisible(true);
    const interval = setInterval(() => {
      setMemberCount(prev => (prev < 500 ? prev + 1 : 500));
    }, 100);
    return () => clearInterval(interval);
  }, []);

  const handleJoinClick = () => {
    // Aquí deberías poner el enlace real de tu grupo de WhatsApp
    window.open('https://chat.whatsapp.com/tu-enlace-aqui', '_blank');
  };

  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className={`max-w-md w-full space-y-8 transform transition-all duration-1000 ease-out ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
        <div className="bg-gray-800 rounded-2xl shadow-2xl overflow-hidden">
            
          <div className="px-6 py-8">
            <h2 className="text-3xl font-extrabold text-center text-white mb-2">
              Grupo de Pronósticos
            </h2>
            <p className="text-center text-gray-300 mb-6">
              Únete a la élite de los pronósticos deportivos y maximiza tus ganancias.
            </p>
            
            <div className="space-y-4 mb-8">
              {[
                { icon: MessageCircle, text: 'Pronósticos exclusivos diarios', color: 'text-green-400' },
                { icon: Users, text: 'Comunidad de expertos 24/7', color: 'text-blue-400' },
                { icon: Zap, text: 'Alertas en tiempo real', color: 'text-yellow-400' }
              ].map((feature, index) => (
                <div key={index} className="flex items-center space-x-3 animate-fade-in-up" style={{animationDelay: `${index * 200}ms`}}>
                  <feature.icon className={`h-6 w-6 ${feature.color}`} />
                  <span className="text-gray-200">{feature.text}</span>
                </div>
              ))}
            </div>
            
            <div className="text-center text-gray-300 mb-6 animate-pulse">
              <Users className="inline-block mr-2 h-5 w-5 text-blue-400" />
              <span>{memberCount}+ miembros ya se unieron</span>
            </div>

            <button
              onClick={handleJoinClick}
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-green-500 to-blue-600 hover:from-green-600 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-all duration-300 transform hover:scale-105"
            >
              Unirse ahora
              <ArrowRight className="ml-2 -mr-1 h-5 w-5" />
            </button>
          </div>
        </div>
        
        <p className="text-center text-xs text-gray-500 mt-4">
          Al unirte, aceptas recibir mensajes del grupo. Puedes salir en cualquier momento.
        </p>
      </div>
    </div>
  );
}