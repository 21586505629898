// src/config/firebase.ts

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database'; // Importar el servicio de Realtime Database
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyDrEAUTO8AkiqVC1P7vhdV79_7Nny_GWXM",
  authDomain: "towin-fe4cc.firebaseapp.com",
  projectId: "towin-fe4cc",
  storageBucket: "towin-fe4cc.appspot.com",
  messagingSenderId: "352819961674",
  appId: "1:352819961674:web:675ae57425c3e3c605abb3",
  measurementId: "G-YKE283R84E",
  databaseURL: "https://towin-fe4cc-default-rtdb.firebaseio.com" // Añadir la URL de la base de datos
};

// Inicializar la aplicación Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Inicializar los servicios de Firebase
const auth = getAuth(app);
const database = getDatabase(app); // Inicializar el servicio de Realtime Database

// Exportar los servicios
export { app, auth, database };
