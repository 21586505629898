import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./App/Pages/NotFound";
import ProtectedRoute from "./Config/ProtectedRoute";
import Home from "./App/Home/Home";
import Login from "./App/login/Login"; // Asegúrate de que el archivo se llame Login.js o Login.jsx
import Register from "./App/sigunp/Register"; // Verifica que el archivo Register esté en la carpeta Signup
import ComprarVip from "./App/Vip/ComprarVip";
import { showMobileOnlyMessage } from "./Config/onlyPhone";
import ForgotPassword from "./App/Profile/ForgotPassword";
import BetFree from "./App/Free/BetFree";
import PanelAdmin from "./Admin/PanelAdmin";

function App() {
  useEffect(() => {
    // Llamar a la función cuando el componente se monta
    showMobileOnlyMessage();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/" element={<ProtectedRoute element={<Home />} />} /> */}
        <Route path="/tips-free" element={<ProtectedRoute element={<BetFree />} />} />
        <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/comprar-vip" element={<ComprarVip />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="/app-admin" element={<ProtectedRoute element={<PanelAdmin />} />} />
        {/* Ruta para manejar páginas no encontradas */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
