import React, { useState, useEffect, useRef } from "react";
import { getDatabase, ref, push, onChildAdded, get } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { Camera, Send } from "lucide-react";
import Image from "../Layout/Image";

export default function SupportChat() {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [image, setImage] = useState(null);
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState("");
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        loadUserName(currentUser.uid);
        loadMessages(currentUser.uid);
      } else {
        // Redireccionar a la página de login si el usuario no está autenticado
        // navigate('/login');
      }
    });

    return () => unsubscribe();
  }, []);

  const loadUserName = (userId) => {
    const db = getDatabase();
    const userRef = ref(db, `users/${userId}`);
    get(userRef).then((snapshot) => {
      if (snapshot.exists()) {
        const name = snapshot.val().name || "Usuario";
        setUserName(name);
        console.log("Loaded userName:", name);
      }
    });
  };

  const loadMessages = (userId) => {
    const db = getDatabase();
    const chatRef = ref(db, `chats/${userId}`);
    onChildAdded(chatRef, (snapshot) => {
      const message = snapshot.val();
      setMessages((prevMessages) => [...prevMessages, message]);
    });
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const uploadImage = async () => {
    if (image) {
      const storage = getStorage();
      const imageRef = storageRef(
        storage,
        `chat-images/${Date.now()}_${image.name}`
      );
      await uploadBytes(imageRef, image);
      return getDownloadURL(imageRef);
    }
    return null;
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() || image) {
      const imageUrl = await uploadImage();
      const db = getDatabase();
      const chatRef = ref(db, `chats/${user.uid}`);
      push(chatRef, {
        text: newMessage,
        sender: user.uid,
        timestamp: Date.now(),
        userName: userName,
        imageUrl: imageUrl,
      });

      setNewMessage("");
      setImage(null);
    }
  };

  // Auto-scroll al último mensaje
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  if (!user) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="flex flex-col h-screen bg-gray-900 pt-10 pb-8">
      {/* Área de mensajes */}
      <div className="flex-1 overflow-y-auto p-4">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`mb-4 ${
              message.sender === user.uid ? "text-right" : "text-left"
            }`}
          >
            <div
              className={`inline-block p-2 rounded-lg ${
                message.sender === user.uid
                  ? "bg-blue-500 text-white"
                  : "bg-white text-gray-800"
              }`}
            >
              <div className="font-semibold">{message.userName}</div>
              <div>{message.text}</div>
              {message.imageUrl && (
                <Image
                  src={message.imageUrl}
                  alt="Imagen"
                  className="mt-2 max-w-xs rounded"
                />
              )}
            </div>
            <div className="text-xs text-gray-500 mt-1">
              {new Date(message.timestamp).toLocaleTimeString()}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Formulario de envío de mensajes (fijo en la parte inferior) */}
      <form
        onSubmit={sendMessage}
        className="bg-white p-4 border-t border-gray-200"
        style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      >
        <div className="flex items-center space-x-2">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="hidden"
            id="image-upload"
          />
          <label htmlFor="image-upload" className="cursor-pointer">
            <Camera size={24} className="text-yellow-400" />
          </label>
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Escribe tu mensaje..."
            className="text-black flex-1 border rounded-l-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-r-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <Send size={20} />
          </button>
        </div>
      </form>
    </div>
  );
}
