import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';

// Función para formatear la fecha
const formatDate = (date) => {
  const options = { day: 'numeric', month: 'long', timeZone: 'America/Bogota' };
  return new Date(date).toLocaleDateString('es-ES', options);
};

// Componente para cada tarjeta de historial
const BetHistoryCard = ({ prediction }) => {
  // Determinar el color del estado
  let statusColor;
  switch (prediction.state) {
    case 'ganada':
      statusColor = 'bg-green-500';
      break;
    case 'pendiente':
      statusColor = 'bg-orange-500';
      break;
    case 'perdida':
      statusColor = 'bg-red-500';
      break;
    case 'nula':
      statusColor = 'bg-white';
      break;
    default:
      statusColor = 'bg-gray-500'; // Color por defecto si el estado es desconocido
  }

  return (
    <div className="relative bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg shadow-lg p-6 mb-4">
      <div className={`absolute inset-y-0 right-0 w-2 ${statusColor}`}></div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-4">
          <img src={prediction.homeTeam.logo} alt={prediction.homeTeam.name} className="w-12 h-12 rounded-full" />
          <span className="text-white font-semibold">vs</span>
          <img src={prediction.awayTeam.logo} alt={prediction.awayTeam.name} className="w-12 h-12 rounded-full" />
        </div>
        <div className="bg-yellow-500 text-gray-900 font-bold py-1 px-3 rounded-full text-sm">
          {prediction.odds.toFixed(2)}
        </div>
      </div>
      <div className="text-center mb-4">
        <h3 className="text-white font-bold text-lg">{prediction.homeTeam.name} vs {prediction.awayTeam.name}</h3>
        <p className="text-gray-400 text-sm">{prediction.prediction}</p>
        <p className="text-gray-400 text-xs mt-2">Fecha: {formatDate(prediction.date)}</p>
      </div>
    </div>
  );
};

const BetHistory = ({ userUUID }) => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const abetRef = ref(db, 'abet');

    // Obtener la fecha actual
    const currentDate = new Date().toISOString().split('T')[0];

    // Cargar los pronósticos desde Firebase
    onValue(abetRef, (snapshot) => {
      const data = snapshot.val();
      const loadedHistory = Object.keys(data)
        .map((key) => ({
          id: key,
          ...data[key],
        }))
        .filter((prediction) => new Date(prediction.date) < new Date(currentDate)); // Filtrar pronósticos anteriores a la fecha actual

      // Filtrar si el userUUID no está presente
      const filteredHistory = userUUID ? loadedHistory : loadedHistory.filter(prediction => prediction.state === 'ganada');

      setHistory(filteredHistory);
    });
  }, [userUUID]);

  return (
    <div className="min-h-screen bg-gray-900 text-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-extrabold text-center mb-10">Historial de Pronósticos</h1>
        {history.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {history.map((prediction) => (
              <BetHistoryCard key={prediction.id} prediction={prediction} />
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-400">No hay pronósticos anteriores.</p>
        )}
      </div>
    </div>
  );
};

export default BetHistory;
