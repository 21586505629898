import React, { useState } from "react";

const Image = ({
  src,
  alt = "Image",
  h = null,
  w = null,
  className = "",
  style,
  onClick = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div
      style={{
        height: h || "auto",
        width: w || "auto",
      }}
      onClick={onClick}
      className="relative w-full h-full flex items-center justify-center"
    >
      {isLoading && (
        <div className="animate-spin absolute rounded-full h-14 w-14 border-t-2 border-b-2 border-blue-500"></div>
      )}
      <img
        src={src}
        alt={alt}
        style={style || {}}
        onLoad={handleImageLoad}
        className={`transition-opacity duration-500 ease-in-out ${
          isLoading ? "opacity-0" : "opacity-100"
        } w-full h-full object-cover ${className}`}
      />
    </div>
  );
};

export default Image;
