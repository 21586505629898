import React from 'react';
import { motion } from 'framer-motion';

const FootballLoading = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-b from-green-400 to-green-600 overflow-hidden">
      <div className="relative w-64 h-64">
        {/* Campo de fútbol */}
        <div className="absolute inset-0 border-4 border-white rounded-full overflow-hidden">
          <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-white"></div>
          <div className="absolute top-0 bottom-0 left-1/2 w-0.5 bg-white"></div>
        </div>

        {/* Balón de fútbol */}
        <motion.div
          className="absolute w-12 h-12 bg-white rounded-full"
          style={{
            backgroundImage: "radial-gradient(circle, transparent 0%, transparent 70%, #000 70%, #000 100%)",
            backgroundSize: "33.33% 33.33%",
            backgroundPosition: "0 0, 50% 0, 100% 0"
          }}
          animate={{
            x: [0, 100, 0, -100, 0],
            y: [0, -100, 0, -100, 0],
            rotate: [0, 360, 720, 1080, 1440],
          }}
          transition={{
            duration: 5,
            ease: "easeInOut",
            times: [0, 0.25, 0.5, 0.75, 1],
            repeat: Infinity,
          }}
        />
      </div>

      {/* Texto de carga */}
      <motion.h2
        className="mt-8 text-4xl font-bold text-white text-center"
        animate={{ opacity: [0.5, 1, 0.5] }}
        transition={{ duration: 1.5, repeat: Infinity }}
      >
        Calentando...
      </motion.h2>

      {/* Barra de progreso */}
      <div className="w-64 h-4 bg-white rounded-full mt-4 overflow-hidden">
        <motion.div
          className="h-full bg-yellow-400"
          animate={{ width: ["0%", "100%"] }}
          transition={{ duration: 2, repeat: Infinity }}
        />
      </div>

      {/* Frases de fútbol */}
      <motion.p
        className="mt-8 text-xl text-white text-center italic"
        animate={{ opacity: [0, 1, 0] }}
        transition={{ duration: 4, repeat: Infinity }}
      >
        "El fútbol se juega con la cabeza, y tus piernas son sólo las herramientas."
      </motion.p>
    </div>
  );
};

export default FootballLoading;