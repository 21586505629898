import React, { useState } from 'react';
import { auth } from '../../Config/firebase'; // Asegúrate de que la ruta sea correcta
import { sendPasswordResetEmail } from 'firebase/auth';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(''); // Limpiar el mensaje previo
    setError(''); // Limpiar errores previos

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Se ha enviado un enlace para restablecer tu contraseña a tu correo electrónico.');
    } catch (error) {
      console.error('Error al enviar el correo de restablecimiento de contraseña:', error);
      setError('No se pudo enviar el correo. Verifica que el correo esté registrado.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="bg-gray-900 p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-3xl font-bold mb-6 text-center text-red-500">Restablecer Contraseña</h2>
        
        {message && <p className="text-green-500 text-sm mb-4">{message}</p>}
        {error && <p className="text-red-600 text-sm mb-4">{error}</p>}
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-gray-400">Correo electrónico:</label>
            <input 
              type="email" 
              id="email" 
              className="w-full px-4 py-2 bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button 
            type="submit" 
            className="w-full bg-red-500 text-white py-2 rounded-lg hover:bg-orange-600 transition duration-300">
            Enviar enlace de restablecimiento
          </button>
        </form>

        {/* Enlace para volver al inicio de sesión */}
        <div className="mt-4 text-center">
          <p className="text-gray-400">
            <a href="/login" className="text-orange-500 hover:text-red-500 transition duration-300">
              Volver a Iniciar Sesión
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
