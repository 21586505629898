// onlyPhone.js
export function isMobileDevice() {
  const mobileRegex = /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop|Windows Phone|BlackBerry|webOS|Mobile/;
  return mobileRegex.test(navigator.userAgent);
}

export function showMobileOnlyMessage() {
  if (!isMobileDevice()) {
    // Crear un contenedor para el mensaje
    const messageContainer = document.createElement('div');
    messageContainer.style.position = 'fixed';
    messageContainer.style.top = '50%';
    messageContainer.style.left = '50%';
    messageContainer.style.transform = 'translate(-50%, -50%)';
    messageContainer.style.width = '90%';
    messageContainer.style.maxWidth = '400px';
    messageContainer.style.backgroundColor = 'rgba(0, 0, 0, 0.85)';
    messageContainer.style.color = '#ffffff';
    messageContainer.style.borderRadius = '10px';
    messageContainer.style.padding = '20px';
    messageContainer.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
    messageContainer.style.zIndex = '9999';
    messageContainer.style.fontSize = '1.2em';
    messageContainer.style.textAlign = 'center';

    messageContainer.innerHTML = `
      <div>
        <p style="margin-bottom: 15px;">Para una mejor experiencia, recomendamos acceder a esta aplicación desde un dispositivo móvil.</p>
        <p style="margin-bottom: 20px;"></p>
        <button id="acceptButton" style="
          background-color: #4CAF50;
          border: none;
          color: white;
          padding: 10px 20px;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          font-size: 16px;
          margin: 4px 2px;
          cursor: pointer;
          border-radius: 5px;
          transition: background-color 0.3s;
        ">Aceptar</button>
      </div>
    `;

    // Añadir el contenedor al body
    document.body.appendChild(messageContainer);

    // Agregar evento al botón de aceptar
    const acceptButton = document.getElementById('acceptButton');
    if (acceptButton) {
      acceptButton.addEventListener('click', () => {
        // Remover el contenedor del mensaje
        document.body.removeChild(messageContainer);
      });
    }
  }
}
