import React from 'react';
import { Link } from 'react-router-dom';
import { Search, Home, RefreshCw } from 'lucide-react';

export default function NotFound() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-blue-900 to-gray-900 text-white flex flex-col justify-center items-center p-4">
      <div className="text-center max-w-2xl">
        <h1 className="text-8xl font-bold mb-4 animate-pulse">
          4<span className="text-yellow-400">0</span>4
        </h1>
        <p className="text-3xl mb-6 font-semibold">¡Fuera de juego!</p>
        <p className="text-xl text-gray-300 mb-8">
          Parece que el árbitro ha pitado falta. No pudimos encontrar la página que estabas buscando.
        </p>
        <div className="flex justify-center space-x-4">
          <Link to="/" className="bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded-full text-lg font-semibold transition-all duration-300 transform hover:scale-105 flex items-center">
            <Home className="mr-2" size={20} />
            Volver al inicio
          </Link>
        </div>
      </div>
      
      <div className="mt-16 relative">
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-gray-900 z-10"></div>
        <img src="https://w7.pngwing.com/pngs/706/599/png-transparent-basketball-court-illustration-football-pitch-athletics-field-stadium-the-uefa-european-football-championship-a-plan-view-of-soccer-field-material-ed-angle-text-sport-thumbnail.png" alt="Campo de fútbol" className="w-64 h-64 object-cover rounded-full animate-spin-slow" />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 animate-bounce">
          <div className="w-16 h-16 bg-white rounded-full border-4 border-black"></div>
        </div>
      </div>
    </div>
  );
}