import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { Search } from 'lucide-react';
import useUserData from '../../Hooks/useUserData';
import FootballLoading from '../../Utils/FootballLoading';

const PredictionCard = ({ prediction }) => {
  return (
    <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105">
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-4">
            <img src={prediction.homeTeam.logo} alt={prediction.homeTeam.name} className="w-12 h-12 rounded-full" />
            <span className="text-white font-semibold">vs</span>
            <img src={prediction.awayTeam.logo} alt={prediction.awayTeam.name} className="w-12 h-12 rounded-full" />
          </div>
          <div className="bg-yellow-500 text-gray-900 font-bold py-1 px-3 rounded-full text-sm">
            {prediction.odds.toFixed(2)}
          </div>
        </div>
        <div className="text-center mb-4">
          <h3 className="text-white font-bold text-lg">{prediction.homeTeam.name} vs {prediction.awayTeam.name}</h3>
          <p className="text-gray-400 text-sm">{prediction.prediction}</p>
        </div>
        <div className="flex justify-between items-center">
          <div className="w-2/3 bg-gray-700 rounded-full h-4">
            <div 
              className="bg-green-500 rounded-full h-4 transition-all duration-500 ease-out"
              style={{ width: `${prediction.confidence}%` }}
            ></div>
          </div>
          <span className="text-green-500 font-bold">{prediction.confidence}%</span>
        </div>
      </div>
    </div>
  );
};

const BetVip = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [predictions, setPredictions] = useState([]);
  const { user } = useUserData(); // Hook para obtener los datos del usuario
  const db = getDatabase();

  useEffect(() => {
    const abetVipRef = ref(db, 'abetVip');

    // Cargar los pronósticos desde Firebase
    onValue(abetVipRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const loadedPredictions = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setPredictions(loadedPredictions);
      }
    });
  }, [db]);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = predictions.filter(prediction => 
      prediction.homeTeam.name.toLowerCase().includes(term) ||
      prediction.awayTeam.name.toLowerCase().includes(term) ||
      prediction.prediction.toLowerCase().includes(term)
    );
    setPredictions(filtered);
  };

  const { userData, loading, error } = useUserData();

  if (loading) {
    return <FootballLoading/>;
  }
  
  if (error) {
    return <p>Error: {error}</p>;
  }
  
  if (userData?.typeU !== 'Cuenta Vip') {
    return (
      <div className="min-h-screen bg-gray-900 text-white py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-4xl font-extrabold mb-6">Acceso VIP Requerido</h1>
          <p className="text-xl text-gray-400 mb-8">
            Esta sección está disponible solo para usuarios VIP. Para ver los pronósticos VIP, adquiere una cuenta VIP.
          </p>
          <a href="/comprar-vip" className="inline-block bg-yellow-500 text-gray-900 font-bold py-3 px-6 rounded-full">
            Comprar Cuenta VIP
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-extrabold text-center mb-10 animate-pulse">
          VIP Sports Predictions
        </h1>

        <div className="mb-8 max-w-md mx-auto">
          <div className="relative">
            <input
              type="text"
              placeholder="Search for teams or predictions..."
              className="w-full bg-gray-800 text-white border border-gray-700 rounded-full py-3 px-6 pl-12 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={handleSearch}
            />
            <Search className="absolute left-4 top-3.5 text-gray-400" size={20} />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {predictions.map(prediction => (
            <PredictionCard key={prediction.id} prediction={prediction} />
          ))}
        </div>

        {predictions.length === 0 && (
          <p className="text-center text-gray-400 mt-8">No predictions found. Try a different search term.</p>
        )}
      </div>
    </div>
  );
};

export default BetVip;
