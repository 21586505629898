import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../Config/firebase'; // Asegúrate de que la ruta sea correcta
import { signInWithEmailAndPassword } from 'firebase/auth';
import FootballLoading from '../../Utils/FootballLoading';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Estado para mostrar el loading
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Mostrar loading

    try {
      // Iniciar sesión con Firebase
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Guardar el UID en localStorage
      localStorage.setItem('userUUID', user.uid);

      // Redirigir al dashboard después de iniciar sesión
      navigate('/home');
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      setError('Credenciales inválidas. Inténtalo de nuevo.');
    } finally {
      setLoading(false); // Ocultar loading
    }
  };

  if (loading) {
    return <FootballLoading />; // Mostrar el componente de loading
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="bg-gray-900 p-8 rounded-lg shadow-lg max-w-md w-full">
        
        {/* Logo */}
        <div className="flex justify-center mb-6">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2" // Cambia esto por la ruta de tu logo
            alt="Logo"
            className="w-24 h-24 rounded-full border-4 border-red-500"
          />
        </div>

        <h2 className="text-3xl font-bold mb-6 text-center text-red-500">Iniciar Sesión</h2>
        {error && <p className="text-red-600 text-sm mb-4">{error}</p>}
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-gray-400">Correo electrónico:</label>
            <input 
              type="email" 
              id="email" 
              className="w-full px-4 py-2 bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-gray-400">Contraseña:</label>
            <input 
              type="password" 
              id="password" 
              className="w-full px-4 py-2 bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button 
            type="submit" 
            className="w-full bg-red-500 text-white py-2 rounded-lg hover:bg-orange-600 transition duration-300">
            Iniciar Sesión
          </button>
        </form>

        {/* Enlaces de ayuda */}
        <div className="mt-4 text-center">
          <p className="text-gray-400">
            <a href="/forgot-password" className="text-orange-500 hover:text-red-500 transition duration-300">
              ¿Olvidaste tu contraseña?
            </a>
          </p>
          <p className="mt-2 text-gray-400">
            ¿No tienes una cuenta? <a href="/register" className="text-orange-500 hover:text-red-500 transition duration-300">Regístrate</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
